import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import * as queries from '../../graphql-custom/queries';
import GrantSuccesses from './GrantSuccesses';
import HelpQueue from './HelpQueue';
import InactiveParticipants from './InactiveParticipants';
import Incentives from './Incentives';
import Layout from '../layout/Layout';
import Loading from '../Loading';

const GET_DASHBOARD_DATA = gql(queries.dashboard);

const breadcrumbs = [
	{
		text: 'Dashboard',
		route: '/',
	},
];

const Dashboard = () => (
	<Layout
		title="Dashboard"
		breadcrumbs={breadcrumbs}
	>
		<Query query={GET_DASHBOARD_DATA} fetchPolicy="no-cache">
			{({ loading, error, data }) => {
				if (loading) return (<Loading />);
				if (error) return (<h3 data-testid="error">{error.message}</h3>);

				const days = data.settings.items.length
					? parseInt(data.settings.items[0].data, 10) : 0;

				const users = data.users.items.filter(u => u.legalFirstName.indexOf('HIDE_') === -1);

				return (
					<div>
						<GrantSuccesses users={users} />
						<div className="card-deck mt-4">
							<div className="card">
								<div className="card-body">
									<h5 className="card-title">Help Queue</h5>
									<HelpQueue helpRequests={data.helpRequests.items} />
								</div>
							</div>
							<div className="card">
								<div className="card-body">
									<h5 className="card-title">Inactive Participants</h5>
									<InactiveParticipants users={users} days={days} />
								</div>
							</div>
							<div className="card">
								<div className="card-body">
									<h5 className="card-title">Due for Incentive</h5>
									<Incentives users={users} />
								</div>
							</div>
						</div>
					</div>
				);
			}}
		</Query>
	</Layout>
);

export default Dashboard;
