import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { Query, withApollo } from 'react-apollo';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
	Warning, Error, Add, Edit, TouchApp, SaveAlt,
} from '@material-ui/icons';
import { CSVLink } from 'react-csv';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import styled from 'styled-components';

import * as queries from '../graphql-custom/queries';
import Loading from './Loading';

const Actions = (cell, row) => (
	<Fragment>
		<Link data-testid="edit-btn" to={`/users/${row.id}/details`}>
			<button type="button" className="btn btn-light">
				<Edit />
			</button>
		</Link>
		<Link data-testid="touch-btn" className="mx-2" to={`/users/${row.id}/touches/new`}>
			<button type="button" className="btn btn-light">
				<TouchApp />
			</button>
		</Link>
	</Fragment>
);

const ActivityIndicator = (cell, row) => (
	<Fragment>
		{row.touchbaseOverdue && (<Error className="ml-1" style={{ fontSize: '18px' }} />)}
		{row.inactive && (<Warning style={{ fontSize: '18px' }} className="ml-1" />)}
	</Fragment>
);

const determineViralLoad = test => (
	test.viralLoad || test.range
);

const determineFirstSuppressedDate = (tests) => {
	const suppressedTests = tests
		.sort((t1, t2) => (t1.testDate < t2.testDate ? 1 : -1))
		.filter(t => !t.viralLoad);

	return suppressedTests.length
		? moment(suppressedTests[0].testDate).format('MM/DD/YYYY')
		: '';
};

const determineFirstSuppressedViralLoad = (tests) => {
	const suppressedTests = tests
		.sort((t1, t2) => (t1.testDate < t2.testDate ? 1 : -1))
		.filter(t => !t.viralLoad);

	return suppressedTests.length
		? suppressedTests[0].range
		: '';
};

const headers = [
	{ label: 'Legal Name', key: 'legalName' },
	{ label: 'Current Viral Load', key: 'viralLoad' },
	{ label: 'Current Viral Load Date', key: 'viralLoadDate' },
	{ label: 'Initial Viral Load', key: 'initialViralLoad' },
	{ label: 'Initial Viral Load Date', key: 'initialViralLoadDate' },
	{ label: 'First Suppression Viral Load', key: 'firstSuppressionViralLoad' },
	{ label: 'First Suppression Viral Load Date', key: 'firstSuppressionViralLoadDate' },
	{ label: 'Email', key: 'email' },
	{ label: 'Enrollment Date', key: 'enrollmentDate' },
	{ label: 'Name', key: 'name' },
	{ label: 'Date of Birth', key: 'dateOfBirth' },
	{ label: 'Age', key: 'age' },
	{ label: 'Ryan White Number', key: 'ryanWhiteNumber' },
	{ label: 'Birth Gender', key: 'birthGender' },
	{ label: 'Other Birth Gender', key: 'otherBirthGender' },
	{ label: 'Sexual Orientation', key: 'sexualOrientation' },
	{ label: 'Other Sexual Orientation', key: 'otherSexualOrientation' },
	{ label: 'Gender', key: 'gender' },
	{ label: 'Other Gender', key: 'otherGender' },
	{ label: 'Sexual/Partner History', key: 'partners' },
	{ label: 'Transgender', key: 'transgender' },
	{ label: 'Partners on PrEP', key: 'prep' },
	{ label: 'Race', key: 'race' },
	{ label: 'Disability', key: 'disability' },
	{ label: 'Household Poverty Percentage', key: 'householdPovertyPercentage' },
	{ label: 'Mental Illness', key: 'mentalIllness' },
	{ label: 'Address', key: 'address' },
	{ label: 'Census Tract Number', key: 'censusTractNumber' },
	{ label: 'County', key: 'county' },
	{ label: 'Phone Number', key: 'phoneNumber' },
	{ label: 'Alternate Phone Number', key: 'alternatePhoneNumber' },
	{ label: 'Preferred Contact Method', key: 'preferredContactMethod' },
	{ label: 'Referred By', key: 'referredBy' },
	{ label: 'Referred By Other', key: 'referredByOther' },
	{ label: 'Total Check-Ins', key: 'totalCheckIns' },
	{ label: 'Total Touchbases', key: 'totalTouchbases' },
	{ label: 'Total Surveys', key: 'totalSurveys' },
	{ label: 'Archived', key: 'archived' },
	{ label: 'Archive Reason', key: 'archivedReason' },
];

const { SearchBar } = Search;

const columns = [
	{
		dataField: 'legalName',
		text: 'Legal Name',
		sort: true,
	},
	{
		dataField: 'df1',
		isDummyField: true,
		text: '',
		formatter: ActivityIndicator,
	},
	{
		dataField: 'ryanWhiteNumber',
		text: 'Ryan White Number',
		sort: true,
	},
	{
		dataField: 'name',
		text: 'Name',
		sort: true,
	},
	{
		dataField: 'dateOfBirth',
		text: 'Date of Birth',
	},
	{
		dataField: 'age',
		text: 'Age',
		sort: true,
	},
	{
		dataField: 'county',
		text: 'County',
		sort: true,
	},
	{
		dataField: 'viralLoad',
		text: 'Viral Load',
		sort: true,
	},
	{
		dataField: 'df2',
		isDummyField: true,
		text: '',
		align: 'right',
		formatter: Actions,
	},
];

class ListUsers extends React.Component {
	static propTypes = {
		archived: PropTypes.bool.isRequired,
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);

		this.csvLink = React.createRef();
		this.state = {
			data: [],
		};
	}

	downloadUsers() {
		const { client } = this.props;
		client.query({
			query: gql(queries.participantsFull),
			fetchPolicy: 'network-only',
			variables: {
				archived: this.props.archived,
			},
		}).then((res) => {
			const data = res.data.listUsers.items.map(user => ({
				legalName: `${user.legalLastName}, ${user.legalFirstName}`,
				viralLoad: user.testResults.items.length ? determineViralLoad(user.testResults.items[0]) : '',
				viralLoadDate: user.testResults.items.length ? moment(user.testResults.items[0].testDate).format('MM/DD/YYYY') : '',
				initialViralLoad: user.testResults.items.length ? determineViralLoad(user.testResults.items[user.testResults.items.length - 1]) : '',
				initialViralLoadDate: user.testResults.items.length ? moment(user.testResults.items[user.testResults.items.length - 1].testDate).format('MM/DD/YYYY') : '',
				firstSuppressionViralLoad: user.testResults.items.length ? determineFirstSuppressedViralLoad(user.testResults.items) : '',
				firstSuppressionViralLoadDate: user.testResults.items.length ? determineFirstSuppressedDate(user.testResults.items) : '',
				email: user.email,
				enrollmentDate: user.enrollmentDate ? moment(user.enrollmentDate).format('MM/DD/YYYY') : '',
				name: user.name,
				dateOfBirth: moment(user.dateOfBirth).format('MM/DD/YYYY'),
				age: moment().diff(user.dateOfBirth, 'years'),
				ryanWhiteNumber: user.ryanWhiteNumber,
				birthGender: user.birthGender ? user.birthGender.description : '',
				otherBirthGender: user.otherBirthGender,
				sexualOrientation: user.sexualOrientation ? user.sexualOrientation.description : '',
				otherSexualOrientation: user.otherSexualOrientation,
				gender: user.gender ? user.gender.description : '',
				otherGender: user.otherGender,
				partners: user.partners ? user.partners.description : '',
				transgender: user.transgender ? 'Yes' : 'No',
				prep: user.prep,
				condomUsage: user.condomUsage,
				race: user.race ? user.race.description : '',
				disability: user.disability ? user.disability.description : '',
				householdPovertyPercentage: user.householdPovertyPercentage,
				mentalIllness: user.mentalIllness ? 'Yes' : 'No',
				address: user.address,
				censusTractNumber: user.censusTractNumber,
				county: user.county ? user.county.description : 'Unspecified County',
				phoneNumber: user.phoneNumber,
				alternatePhoneNumber: user.alternatePhoneNumber,
				preferredContactMethod: user.preferredContactMethod ? user.preferredContactMethod.description : '',
				referredBy: user.referredBy ? user.referredBy.description : '',
				referredByOther: user.referredByOther,
				totalCheckIns: user.totalCheckIns,
				totalTouchbases: user.totalTouchbases,
				totalSurveys: user.totalSurveys,
				archived: user.archived ? 'Yes' : 'No',
				archivedReason: user.archivedReason ? user.archivedReason.description : '',
			}));

			this.setState({
				data,
			}, () => {
				this.csvLink.current.link.click();
			});
		});
	}

	render() {
		const LIST_USERS = gql(queries.participants);

		return (
			<Query query={LIST_USERS} fetchPolicy="cache-and-network" variables={{ archived: this.props.archived }}>
				{({ loading, error, data }) => {
					if (error) return (<h3 data-testid="error">{JSON.stringify(error)}</h3>);
					if (loading) return (<Loading />);

					const today = moment();
					const days = data.settings.items.length
						? parseInt(data.settings.items[0].data, 10) : 0;

					const users = data.listUsers.items
						.filter(u => u.legalFirstName.indexOf('HIDE_') === -1)
						.sort((a, b) => {
							if (a.legalLastName > b.legalLastName) {
								return 1;
							}

							if (a.legalLastName === b.legalLastName) {
								return a.legalFirstName > b.legalFirstName ? 1 : -1;
							}
							return -1;
						}).map((user) => {
							const touchFrequencyDays = user.touchFrequency
								? user.touchFrequency.data : null;
							const lastTouchDate = user.touches.items.length
								? user.touches.items[0].touchDate : null;

							return {
								id: user.id,
								legalName: `${user.legalLastName}, ${user.legalFirstName}`,
								ryanWhiteNumber: user.ryanWhiteNumber,
								name: user.name,
								dateOfBirth: moment(user.dateOfBirth).format('MM/DD/YYYY'),
								age: today.diff(user.dateOfBirth, 'years'),
								county: user.county ? user.county.description : 'Unspecified County',
								viralLoad: user.testResults.items.length ? determineViralLoad(user.testResults.items[0]) : '',
								touchbaseOverdue: touchFrequencyDays
									&& (!lastTouchDate || Math.abs(moment(lastTouchDate).diff(today, 'days')) >= touchFrequencyDays),
								inactive: !user.lastActiveAt || (user.lastActiveAt && Math.abs(moment(user.lastActiveAt).diff(today, 'days')) >= days),
							};
						});

					return (
						<div className="card mb-3 mt-3">
							<div className="card-body">
								<ToolkitProvider
									keyField="id"
									data={users}
									columns={columns}
									search
								>
									{
										props => (
											<div>
												<div className="mb-4 d-flex">
													<Buttons className="input-group mb-4">
														<div className="input-group-prepend">
															{!this.props.archived && (
																<Link to="users/new" className="btn btn-circle btn-primary mr-1">
																	<Add />
																</Link>
															)}
															<CSVLink
																data={this.state.data}
																filename="data.csv"
																className="hidden"
																ref={this.csvLink}
																headers={headers}
																target="_blank"
															/>
															<button type="button" className="btn btn-info" onClick={() => this.downloadUsers()}>
																<SaveAlt />
															</button>
														</div>
													</Buttons>
													<Legend className="ml-4">
														<div>
															<Error /> = Due for touchbase
														</div>
														<div className="mt-2">
															<Warning /> = Inactive past day threshold
														</div>
													</Legend>
													<SearchBar {...props.searchProps} />
												</div>
												<BootstrapTable
													bordered={false}
													sort="legalName"
													pagination={paginationFactory({ hidePageListOnlyOnePage: true })}
													noDataIndication="There are no participants to display."
													{...props.baseProps}
												/>
											</div>
										)
									}
								</ToolkitProvider>
							</div>
						</div>
					);
				}}
			</Query>
		);
	}
}

const Legend = styled.div`
	color: gray;
	font-size: .85em;
	width: 400px;
	flex: 1;
`;

const Buttons = styled.div`
	width: auto;
`;

export default withApollo(ListUsers);
