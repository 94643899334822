/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://qo2yjul7vzajvnygiiqnbf5n5e.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "icanrest",
            "endpoint": "https://mtth73bow6.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:6037a899-c5ab-4495-8727-2f867fcfafb9",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_R5iNUTetT",
    "aws_user_pools_web_client_id": "2nhit92bms5kdb41g2fu49u9r7",
    "oauth": {},
    "aws_user_files_s3_bucket": "icanstorage-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "icanhosting-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://icanhosting-prod.s3-website-us-east-1.amazonaws.com",
    "aws_mobile_analytics_app_id": "0dbaa0e20d1a4b6bb395d30a5c71dc8b",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
