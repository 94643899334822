import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const columns = [
	{
		dataField: 'name',
		text: 'Name',
	},
	{
		dataField: 'lastActiveAt',
		text: 'Last Active On',
		formatter: (cell, row) => (row.lastActiveAt ? moment(row.lastActiveAt).format('MM/DD/YYYY') : 'Never'),
	},
];

const InactiveParticipants = ({ users, days }) => {
	if (users.length === 0) return (<h3 data-testid="no-data">No Data</h3>);

	const today = new Date();
	const inactiveUsers = users
		.filter(u => u.legalFirstName.indexOf('HIDE_') === -1)
		.filter(u => !u.lastActiveAt || (u.lastActiveAt && Math.abs(moment(u.lastActiveAt).diff(today, 'days')) >= days));

	return (
		<BootstrapTable
			keyField="id"
			bordered={false}
			data={inactiveUsers}
			noDataIndication="There are no inactive participants."
			columns={columns}
			pagination={paginationFactory({ hidePageListOnlyOnePage: true })}
		/>
	);
};

InactiveParticipants.propTypes = {
	users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	days: PropTypes.number.isRequired,
};

export default InactiveParticipants;
