import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import {
	Add,
	SaveAlt,
	Edit,
} from '@material-ui/icons';

import * as queries from '../graphql-custom/queries';
import Layout from './layout/Layout';
import Loading from './Loading';

class ListEmployees extends Component {
	render() {
		const GET_EMPLOYEES = gql(queries.employees);

		return (
			<Layout title="Employees">
				<Query fetchPolicy="no-cache" query={GET_EMPLOYEES}>
					{({ loading, error, data }) => {
						if (error) return (<h3>Error!</h3>);
						if (loading) return (<Loading />);

						return (
							<Fragment>
								<div className="card mb-3">
									<div className="card-body">
										<div className="mb-4 row">
											<div className="col-md-3">
												<div className="btn-group" role="group" aria-label="Basic example">
													<Link to="employees/new" className="btn btn-circle btn-primary mr-1">
														<Add />
													</Link>
													{data.employees.items.length > 0 && (
														<button type="button" className="btn btn-info">
															<SaveAlt />
														</button>
													)}
												</div>
											</div>
										</div>
										<table className="table table-sm">
											<thead>
												<tr>
													<th>Email Address</th>
													<th>First Name</th>
													<th>Last Name</th>
													<th>Role</th>
													<th>Active</th>
													<th />
												</tr>
											</thead>
											<tbody>
												{data.employees.items.sort((e1, e2) => (e1.lastName < e2.lastName ? -1 : 1)).map(employee => (
													<tr key={employee.id}>
														<td>{employee.email}</td>
														<td>{employee.firstName}</td>
														<td>{employee.lastName}</td>
														<td>{employee.role === 'ADMINISTRATOR' ? 'Administrator' : 'General User'}</td>
														<td>{employee.active ? 'Yes' : 'No'}</td>
														<td className="text-right">
															<Link to={`employees/${employee.id}`}>
																<button type="button" className="btn btn-light">
																	<Edit />
																</button>
															</Link>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</Fragment>
						);
					}}
				</Query>
			</Layout>
		);
	}
}

export default ListEmployees;
